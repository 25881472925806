<template>
    <!-- LOADER -->
    <div id="preloader" v-if="preloader">
        <div class="loader-container">
            <div class="progress-br float shadow">
                <div class="progress__item"></div>
            </div>
        </div>
    </div>
    <!-- END LOADER -->	
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            preloader: state => state.preloader
        })
    }
}
</script>